<template>
  <ColoredLayout>
    <v-container fluid>
      <v-row class="pt-3">
        <v-col cols="12">
          <div
            class="d-flex text-center align-center justify-center pa-3"
            style="width: 100%; height: 75vh"
          >
            <div v-if="loading">
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </div>
            <div v-else>
              <transition name="slide-fade">
                <div>
                  <v-icon x-large :color="iconColor">{{ icon }}</v-icon>
                  <h2 class="accent--text font-weight-bold my-5">
                    {{ message }}
                  </h2>
                </div>
              </transition>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </ColoredLayout>
</template>

<script>
import axios from "axios";
import ColoredLayout from "../components/Layouts/ColoredLayout.vue";

export default {
  name: "AppointmentConfirmation",
  components: {
    ColoredLayout,
  },
  data() {
    return {
      loading: true,
      message: "",
      icon: "",
      iconColor: "",
    };
  },
  methods: {
    async confirmation() {
      try {
        this.loading = true;

        const token = this.$route?.params?.token;
        const status = this.$route.query?.status;
        const doctorId = this.$route.query?.doctorId;

        if (!token || !status || !doctorId) {
          throw Error("Missing params");
        }

        await axios.put(`/api/appointment/${token}/confirmation`, {
          status,
          doctorId,
        });

        if (status == "confirmado") {
          this.iconColor = "success";
          this.icon = "mdi-check-circle";
          this.message = "¡Gracias por confirmar su asistencia en su cita!";
        } else {
          this.iconColor = "accent";
          this.icon = "mdi-information-slab-circle-outline";
          this.message =
            "Lamentamos que no puedas asistir. ¡Esperamos verlo/a pronto!";
        }
      } catch (error) {
        this.iconColor = "error";
        this.icon = "mdi-information-slab-circle-outline";
        this.message = "Algo salió mal, intente más tarde...";
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.confirmation();
  },
};
</script>

<style scoped>
.slide-fade-enter-active {
  transition: all 0.2s ease;
}
.slide-fade-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for <2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>
